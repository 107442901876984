import * as Client from '@inway/webauthn-client';
import { useEffect, useState } from 'react';
import useRegistry from '../hooks/useRegistry';

function Login() {
  const [registry] = useRegistry();
  const [pkcAvailable, setPkcAvailable] = useState(false);
  const [hasCMA, setHasCMA] = useState(false);
  const [options, setOptions] =
    useState<Client.PublicKeyCredentialRequestOptionsOnWire | null>(null);
  const [credentials, setCredentials] =
    useState<Client.AssertionResponse | null>(null);
  const [error, setError] = useState<string>();
  const [result, setResult] = useState<any>();

  const assertCredentials = async (
    credentials: Client.AssertionResponse,
    options: Client.PublicKeyCredentialRequestOptionsOnWire
  ) => {
    try {
      const assureRequest = await fetch('/api/assure', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          credentials,
          getOptions: options,
          passkey: true,
          registry,
        }),
        credentials: 'include',
      });

      if (!assureRequest.ok)
        throw new Error(
          `Invalid response for attestation request ${assureRequest.status}`
        );

      const { result, credentialSource } = await assureRequest.json();

      setError(undefined);
      setResult({ result, credentialSource });

      console.log({ result, credentialSource });
    } catch (err: any) {
      console.error(err);

      setError(`assert: ${err.message}`);
    }
  };

  const autoLogin = async (abortController: AbortController) => {
    if (
      window.PublicKeyCredential &&
      PublicKeyCredential.isConditionalMediationAvailable
    ) {
      setPkcAvailable(true);

      const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();
      setHasCMA(isCMA);

      if (await Client.isConditionalMediationAvailable()) {
        let currentOptions = options;
        if (!currentOptions) {
          const optionsRequest = await fetch('/api/get', {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              allowCredentials: [],
              userVerification: 'preferred',
            }),
            credentials: 'include',
          });
          if (!optionsRequest.ok)
            throw new Error(
              `Invalid response to options request ${optionsRequest.status}`
            );

          const options = await optionsRequest.json();
          if (!options) return;
          setOptions(options);

          currentOptions = options;
        }

        if (!currentOptions) return;

        try {
          const credentials = await Client.conditionalMediation(
            currentOptions,
            abortController
          );

          setCredentials(credentials);

          if (!credentials) return;

          assertCredentials(credentials, currentOptions);
        } catch (error: any) {
          if (error.name === 'NotAllowedError') {
            setError('autofill: User denied');
          } else {
            setError(`autofill: ${error.message}`);
          }
        }
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    autoLogin(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <div className="form-login w-100 m-auto">
      <div className="p-2 mb-4 border rounded border-primary">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="pkc"
            checked={pkcAvailable}
            disabled
          />
          <label className="form-check-label" htmlFor="pkc">
            PublicKeyCredential
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="cma"
            checked={hasCMA}
            disabled
          />
          <label className="form-check-label" htmlFor="cma">
            isConditionalMediationAvailable
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="rpId"
            checked={options?.rpId != undefined}
            disabled
          />
          <label className="form-check-label" htmlFor="rpId">
            rpId: {options?.rpId != undefined ? options?.rpId : 'pending'}
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="challenge"
            checked={options?.challenge != undefined}
            disabled
          />
          <label
            className="form-check-label"
            htmlFor="challenge"
            style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
          >
            challenge:{' '}
            {options?.challenge != undefined
              ? `${options?.challenge}`
              : 'pending'}
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="credential"
            checked={credentials != undefined}
            disabled
          />
          <label
            className="form-check-label"
            htmlFor="credential"
            style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
          >
            credential:{' '}
            {credentials != undefined ? `${credentials.id}` : 'none'}
          </label>
        </div>
        {credentials && (
          <div className="d-flex flex-column">
            <pre>
              <code>{JSON.stringify(credentials, null, 2)}</code>
            </pre>
            <a
              href="#"
              className="btn btn-sm btn-primary"
              onClick={(ev) => {
                ev.preventDefault();

                assertCredentials(credentials, options!);
              }}
            >
              Assert
            </a>
          </div>
        )}
        {error && <div className="p-1 text-danger">{error}</div>}
        {result && (
          <pre>
            <code>{JSON.stringify(result, null, 2)}</code>
          </pre>
        )}
      </div>

      <form>
        <div className="form-floating">
          <input
            type="email"
            className="form-control"
            id="username"
            placeholder="user@example.com"
            autoComplete="username webauthn"
            // value={''}
            // onChange={(e) => setUsername(e.target.value)}
          />
          <label htmlFor="username">Email address</label>
        </div>
        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
          />
          <label htmlFor="password">Password</label>
        </div>

        <button className="btn btn-primary w-100 py-2" type="submit">
          Sign in
        </button>
      </form>
    </div>
  );
}

export default Login;
