import assertion, { AssertionArgs } from '../assertion';
import Layout from '../components/Layout';
import Login from '../components/Login';
import registration, { RegistrationArgs } from '../registration';
import { RegistryEntry } from '../types';

export interface LoginProps {
  version?: string;
}

export default function LoginPage({ version }: LoginProps) {
  const onRegistration = (args: RegistrationArgs, registry: RegistryEntry[]) =>
    registration(args, registry);

  const onAssertion = (
    args: AssertionArgs,
    username: string,
    registry: RegistryEntry[]
  ) => assertion(args, username, registry);

  return (
    <Layout version={version}>
      <Login />
    </Layout>
  );
}

export async function getStaticProps() {
  const { version } = await import('../../package.json');

  return {
    props: {
      version,
    },
  };
}
